import draggable from 'vuedraggable';
import AccountScheduleModal from 'vue_root/components/account-schedule-modal/account-schedule-modal';
import AllocatePercentageModal from './components/allocate-percentage-modal/allocate-percentage-modal.vue';
import { cloneDeep } from 'lodash';
import formatAsDecimal from 'vue_root/mixins/formatAsDecimal.mixin';
import sortBankAccounts from 'vue_root/mixins/sortBankAccounts.mixin.js';

export default {
    components: {
        draggable,
        AccountScheduleModal,
        AllocatePercentageModal,
    },
    filters: {
        formatDate: function(date){
            return Vue.moment(date).format('MM/DD/YY');
        }
    },
    mixins: [formatAsDecimal, sortBankAccounts],
    data: data,
    computed: getComputed(),
    watch: getWatchers(),
    created: created,
    methods: getMethods(),
    beforeRouteLeave: beforeRouteLeave,
};

function beforeRouteLeave(to, from, next){
    const vm = this;
    if(vm.expectedFinalAllocateAmount > 0 && !vm.forceLeavePage){
        vm.redirectRoute = to.name;
        vm.$refs.confirmUnsavedChangesModal.show();
    } else {
        window.removeEventListener('resize', vm.updateChartOptions);
        next();
    }
}

function created(){
    const vm = this;
    vm.isInitializingView = true;
    vm.refreshAllData().finally(resetLoadingState);

    window.addEventListener('resize', vm.updateChartOptions);
    function resetLoadingState(){
        vm.updateChart();
        vm.isInitializingView = false;
    }
}

function data(){
    return {
        allocationAccounts: [],
        apiErrors: [],
        isInitializingView: false,
        incomeAccount: null,
        payoffAccount: null,
        primaryCheckingAccount: {},
        selectedBankAccount: null,
        defaultSchedulePeriod: 'Twice a month',
        optionsSchedulePeriod: ['Once a month', 'Twice a month'],
        chartData: {
            labels: [],
            series: [],
        },
        chartOptions: {
            donut: true,
            donutSolid: true,
            donutWidth: 160,
            ignoreEmptyValues: true,
            showLabel: true,
            startAngle: 105,
        },
        showSuccessMessage: false,
        showPercentageModal: false,
        redirectRoute: null,
        isAllocating: false,
        currentOrganizePreference: null,
        chartElement: null,
        forceLeavePage: false,
    };
}

function getComputed(){
    return {
        sortedAllocationAccounts(){
            const vm = this;
            return vm.allocationAccounts.sort(vm.byModifiedStoreOrder);
        },
        isFetchingDataFromPlaid(){
            return this.$store.state.authorized.isFetchingDataFromPlaid;
        },
        totalPlannedAmount(){
            const vm = this;
            const totalPlanned = vm.allocationAccounts
                .filter(allocationAccount => !allocationAccount.allocateFinalAmount)
                .reduce((total, allocationAccount) => new Decimal(total).plus(new Decimal(vm.getAmountToAllocate(allocationAccount))), 0);
            return totalPlanned;
        },
        showSchedulePeriodSelect(){
            const hasAnySchedulePlan = this.allocationAccounts.some(allocationAccount => allocationAccount.planMode === 'schedule');
            return hasAnySchedulePlan;
        },
        expectedFinalAllocateAmount(){
            const vm = this;
            const totalAllocateAmount = vm.allocationAccounts
                .reduce(
                    (balance, allocationAccount) => new Decimal(balance).plus(allocationAccount.allocateFinalAmount || 0),
                    0
                );
            return totalAllocateAmount;
        },
        expectedIncomeBalance(){
            const vm = this;
            const incomeAccountAvailable = vm.incomeAccount && vm.incomeAccount.balance_available;
            if(!incomeAccountAvailable){
                return 0;
            }

            const remainingBalance = vm.incomeAccount.balance_available - vm.expectedFinalAllocateAmount;
            return remainingBalance;
        },
    };
}

function getWatchers(){
    return {
        isFetchingDataFromPlaid(oldStatus, newStatus){
            if(oldStatus && !newStatus){
                created.apply(this);
            }
        },
        '$store.state.incomeAccountOverview.errorMessage'(newValue){
            this.apiErrors.push(newValue);
        },
        '$store.state.allocationAccounts.errorMessage'(newValue){
            this.apiErrors.push(newValue);
        },
    };
}

function getMethods(){
    return {
        displayApiError,
        openAccountSchedule,
        openAllocatePercentageModal,
        loadAllocationAccounts,
        refreshAllData,
        togglePlanMode,
        updateChart,
        getExpectedAmount,
        getAmountToAllocate,
        getFinalAllocatePercentage,
        proceedAllocate,
        validateFinalAmount,
        save,
        onChangeSchedulePeriod,
        getOrganizePreference,
        storeOrganizePreference,
        hideModal,
        onClickAllocationErrorMessage,
        loadOrganizeOverview,
        updateChartOptions,
    };

    function loadAllocationAccounts(){
        const vm = this;
        return vm.$store.dispatch('allocationAccounts/GET_DATA', true).then(setAllocationAccounts);

        function setAllocationAccounts(){
            vm.allocationAccounts = cloneDeep(vm.$store.state.allocationAccounts.data).map(setAllocationProperty);
        }

        function setAllocationProperty(bankAccount){
            const hasScheduledItems = bankAccount.schedule_items.length > 0;
            bankAccount.planMode = hasScheduledItems ? 'schedule' : 'percentage';
            bankAccount.allocatePercentage = 0;
            bankAccount.allocateScheduleAmount = 0;
            bankAccount.allocateFinalAmount = '';
            bankAccount.isDirty = false;
            return bankAccount;
        }
    }

    function displayApiError(response){
        const vm = this;
        if(response && response.appMessage){
            vm.apiErrors.push(response.appMessage);
        }
    }

    function openAccountSchedule(bankAccount){
        const vm = this;
        vm.selectedBankAccount = bankAccount;
        Vue.nextTick(() => {
            vm.$refs.accountScheduleModal.show();
        });
    }

    function openAllocatePercentageModal(){
        this.$refs.allocatePercentageModal.show();
    }

    function refreshAllData(){
        const vm = this;

        const dataPromises = [
            vm.loadAllocationAccounts(),
            vm.loadOrganizeOverview(),
        ];

        return Promise.all(dataPromises).then(vm.getOrganizePreference).catch(vm.displayApiError);
    }

    function loadOrganizeOverview(){
        const vm = this;
        return Vue.appApi().authorized().organize().overview().then(({ data }) => {
            const { primary_account, income_account } = data;
            setPrimaryCheckingAccount(primary_account);
            setIncomeAccount(income_account);
        });

        function setPrimaryCheckingAccount(account){
            if(account){
                vm.primaryCheckingAccount = account || {};
            }
        }

        function setIncomeAccount(account){
            vm.incomeAccount = account;
        }
    }

    function togglePlanMode(bankAccount){
        const newPlanMode = bankAccount.planMode === 'percentage' ? 'schedule' : 'percentage';
        bankAccount.planMode = newPlanMode;

        const swithingToSchedule = newPlanMode === 'schedule';
        if(swithingToSchedule){
            this.$store.dispatch('scheduleItem/GET_DATA', { id: bankAccount.id, force: true });
        }

        this.storeOrganizePreference();
    }

    function updateChart(){
        const vm = this;
        const chartData = {
            labels: [],
            series: [],
        };

        const chartAccountList = [vm.incomeAccount, ...vm.allocationAccounts, vm.payoffAccount];
        const accounts = cloneDeep(chartAccountList);
        const remainingIncomeBalance = vm.allocationAccounts
            .reduce(
                (balance, allocationAccount) => balance.toFixed(4) - (allocationAccount.allocateFinalAmount || 0),
                vm.incomeAccount.balance_available
            );

        accounts.forEach(addAccountToChartData);
        vm.chartData = chartData;

        function addAccountToChartData(bankAccount){
            if(bankAccount !== null){
                const className = `${bankAccount.color}-slice`;
                const isIncomeAccount = bankAccount.slug === 'income_deposit';
                const balance = isIncomeAccount ? remainingIncomeBalance : vm.getExpectedAmount(bankAccount);
                const label = vm.formatAsDecimal(balance, 'currency');
                const seriesItem = {
                    className,
                    value: Math.max(0, balance),
                    meta: {
                        id: bankAccount.id,
                        target: `ct-${bankAccount.id}`,
                    }
                };

                chartData.series.push(seriesItem);
                chartData.labels.push(label);
            }
        }
    }

    function getExpectedAmount(bankAccount){
        return (new Decimal(bankAccount.balance_available)).plus(new Decimal(bankAccount.allocateFinalAmount || 0));
    }

    function getAmountToAllocate(bankAccount){
        const vm = this;
        const isPlanModePercentage = bankAccount.planMode === 'percentage';
        if(isPlanModePercentage){
            const planAmountPercentage = bankAccount.allocatePercentage * vm.incomeAccount?.balance_available / 100;
            return planAmountPercentage.toFixed(4);
        } else {
            const bankAccountScheduleItemStoreData = vm.$store.state.scheduleItem.data.find(i => i.id === bankAccount.id);
            var bankAccountScheduleItems = [];
            if(bankAccountScheduleItemStoreData !== undefined){
                bankAccountScheduleItems = bankAccountScheduleItemStoreData.data || [];
            }

            var accountScheduledAmount = (bankAccountScheduleItems || []).reduce((total, scheduleItem) => total + scheduleItem.amount_monthly, 0);
            const cutInHalf = vm.defaultSchedulePeriod === 'Twice a month';
            if(cutInHalf){
                accountScheduledAmount = accountScheduledAmount / 2;
            }

            return accountScheduledAmount.toFixed(4);
        }
    }

    function getFinalAllocatePercentage(bankAccount){
        const vm = this;
        const incomeAccountAvailable = vm.incomeAccount && vm.incomeAccount.balance_available;
        if(!incomeAccountAvailable){
            return '0 %';
        }

        const finalAllocatePercentage = bankAccount.allocateFinalAmount * 100 / vm.incomeAccount.balance_available;
        return finalAllocatePercentage.toFixed(0) + ' %';
    }

    function proceedAllocate(bankAccount){
        const vm = this;
        const amountToAllocate = vm.getAmountToAllocate(bankAccount);
        if(amountToAllocate > 0){
            bankAccount.allocateFinalAmount = amountToAllocate;
            vm.validateFinalAmount(bankAccount);
        }
    }

    function validateFinalAmount(bankAccount){
        const vm = this;
        const incomeAccountAvailable = vm.incomeAccount && vm.incomeAccount.balance_available;
        if(!incomeAccountAvailable){
            bankAccount.allocateFinalAmount = 0;
            return;
        }

        bankAccount.hasValidationError = false;
        var remainingIncomeBalance = vm.allocationAccounts
            .filter(allocationAccount => allocationAccount.id !== bankAccount.id)
            .reduce(
                (balance, allocationAccount) => balance - (allocationAccount.allocateFinalAmount || 0),
                vm.incomeAccount.balance_available
            );
        remainingIncomeBalance = remainingIncomeBalance.toFixed(2);

        if(parseFloat(bankAccount.allocateFinalAmount) > parseFloat(remainingIncomeBalance)){
            bankAccount.allocateFinalAmount = remainingIncomeBalance;
            bankAccount.hasValidationError = true;
            bankAccount.allocationErrorMessage = `There is only $ ${remainingIncomeBalance} remaining to be organized.`;
        }

        vm.updateChart();
    }

    function save(){
        const vm = this;
        const payload = [];

        vm.allocationAccounts.forEach(addAllocationRecord);
        const hasAnyAllocationToMake = payload.length;
        if(!hasAnyAllocationToMake){
            return;
        }

        vm.isAllocating = true;
        return Vue.appApi().authorized().bankAccount().allocateFunds(vm.incomeAccount.id, payload)
            .then(handleAllocationSuccess)
            .catch(vm.displayApiError)
            .finally(resetLoadingState);

        function addAllocationRecord(bankAccount){
            const hasAmountToAllocate = bankAccount.allocateFinalAmount > 0;
            if(hasAmountToAllocate){
                payload.push({
                    bankAccountId: bankAccount.id,
                    amount: bankAccount.allocateFinalAmount
                });
            }
        }

        function handleAllocationSuccess(){
            vm.refreshAllData().then(updateBankAccountBalances);
            vm.showSuccessMessage = true;
        }

        function updateBankAccountBalances(){
            const allBankAccounts = [vm.incomeAccount, ...vm.allocationAccounts];
            allBankAccounts.forEach(updateBankAccountBalance);

            function updateBankAccountBalance(bankAccount){

                vm.$store.commit('authorized/bankAccounts/UPDATE_BANK_BALANCE_PROPERTIES', bankAccount);
            }
        }

        function resetLoadingState(){
            vm.isAllocating = false;
        }
    }

    function onChangeSchedulePeriod(newSchedulePeriod){
        this.defaultSchedulePeriod = newSchedulePeriod;
        this.storeOrganizePreference();
    }

    function getOrganizePreference(){
        const vm = this;
        Vue.appApi().authorized().account().organizePreference().getOrganizePreference().then(setOrganizePreference).catch(vm.displayApiError);
        function setOrganizePreference(response){
            vm.currentOrganizePreference = response.data;
            if(vm.currentOrganizePreference){
                vm.allocationAccounts.forEach(allocationAccount => {
                    const savedAllocatePercentage = vm.currentOrganizePreference.allocate_percentage[`${allocationAccount.id}`];
                    if(savedAllocatePercentage){
                        allocationAccount.allocatePercentage = savedAllocatePercentage;
                    }

                    const savedPlanMode = vm.currentOrganizePreference.allocate_mode[`${allocationAccount.id}`];
                    if(savedPlanMode){
                        allocationAccount.planMode = savedPlanMode;
                        const shouldLoadSchedule = savedPlanMode === 'schedule';
                        if(shouldLoadSchedule){
                            vm.$store.dispatch('scheduleItem/GET_DATA', { id: allocationAccount.id, force: true });
                        }
                    }
                });

                if(vm.currentOrganizePreference.schedule_period){
                    vm.defaultSchedulePeriod = vm.currentOrganizePreference.schedule_period;
                }
            }
        }
    }

    function storeOrganizePreference(){
        const vm = this;
        const allocatePercentages = vm.allocationAccounts.reduce((json, allocationAccount) => ({
            ...json,
            [allocationAccount.id]: allocationAccount.allocatePercentage || 0,
        }), {});
        const allocateModes = vm.allocationAccounts.reduce((json, allocationAccount) => ({
            ...json,
            [allocationAccount.id]: allocationAccount.planMode || '',
        }), {});

        const storeOrganizePreferencePayload = {
            allocate_percentage: allocatePercentages,
            allocate_mode: allocateModes,
            schedule_period: vm.defaultSchedulePeriod
        };

        if(vm.currentOrganizePreference){
            storeOrganizePreferencePayload.id = vm.currentOrganizePreference.id;
        }

        Vue.appApi().authorized().account().organizePreference().storeOrganizePreference(storeOrganizePreferencePayload).then(handleSuccess).catch(vm.displayApiError);
        function handleSuccess(response){
            vm.currentOrganizePreference = response.data;
        }
    }

    function hideModal(){
        this.forceLeavePage = true;
        this.$router.push({ name: this.redirectRoute });
    }

    function onClickAllocationErrorMessage(accountId){
        const vm = this;
        const accountIndex = vm.allocationAccounts.findIndex((account) => account.id === accountId);
        const updateAccountError = {
            ...vm.allocationAccounts[accountIndex],
            hasValidationError: false,
            allocationErrorMessage: '',
        };
        vm.$set(vm.allocationAccounts, accountIndex, updateAccountError);
        vm.$set(vm.allocationAccounts, accountIndex, updateAccountError);
    }

    function updateChartOptions(){
        if(window.innerWidth < 576){
            this.chartOptions.donutWidth = 115;
        } else {
            this.chartOptions.donutWidth = 160;
        }
    }
}
